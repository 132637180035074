<!-- eslint-disable -->
<template>
  <div>
    <VTitle :title="$t('app.progress')" class="section-title" />

    <VList
      :rows="resource.outline"
      :headers="headers"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :name="getText(item?.texts, 'name')" :color="item.colour" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.training="{ item }">
        <div
          class="cursor-pointer"
          @click="onClickRedirectToRegistrationTraining(item.id)"
        >
          {{ getText(item?.texts, "name") }}
        </div>

         <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item?.version }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.registered_on="{ item }">
        {{ formatDate({ date: item?.registered_on }) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.learner_completed_on="{ item }">
        {{ formatDate({ date: item?.learner_completed_on }) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.result="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${getCurrentAttempt(item).result}`)"
            :class="getStatusColor(getCurrentAttempt(item).result)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${getCurrentAttempt(item).status}`)"
            :class="getStatusColor(getCurrentAttempt(item).status)"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
// Components
import VTitle from "@/components/VTitle";
import VList from "@/components/tables/VList";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";

export default {
  components: {
    VTitle,
    VList,
    VChip,
    VImage
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { getStatusColor } = useColor();
    const { getText } = useTexts();
    const { getAvatarURL, formatDate } = useDisplay();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.trainings"),
        value: "training"
      },
      {
        text: t("app.registered_on"),
        value: "registered_on"
      },
      {
        text: t("app.learner_completed_on"),
        value: "learner_completed_on"
      },
      {
        text: t("app.result"),
        value: "result",
        headerClass: "justify-center"
      },
      {
        text: t("app.status"),
        value: "status"
      }
    ];

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.progress")} - ${t("app.collections", 2)} - ${t("app.registrations", 2)} - ${t("app.teaching")}`;

      const firstname = props.resource?.learner?.firstname;
      const lastname = props.resource?.learner?.lastname;
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const onClickRedirectToRegistrationTraining = id => {
      router.push({
        name: "teaching-registrations-trainings-update",
        params: { id }
      });
    };

    const getCurrentAttempt = item => {
      const data = item?.learner_data ?? [];
      const value = data.find(x => x.learner_completed_on === null);

      if (value) {
        return value;
      }

      const values = data.slice().sort((a, b) => {
        const dateA = new Date(a.learner_completed_on);
        const dateB = new Date(b.learner_completed_on);

        return dateB - dateA;
      });

      return values[0] ?? {};
    };

    return {
      headers,
      onClickRedirectToRegistrationTraining,
      documentTitle,
      getCurrentAttempt,
      // useColor
      getStatusColor,
      // useTexts
      getText,
      // useDisplay
      formatDate,
      getAvatarURL
    };
  }
};
</script>
